import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { UnleashClient } from 'unleash-proxy-client';


let userId = localStorage.getItem('userId');

if(!userId) {
    userId = Math.round(Math.random() * 100000000);
    localStorage.setItem('userId', userId);
};

// eslint-disable-next-line
posthog.identify(userId);

const unleash = new UnleashClient({
    url: 'https://app.unleash-hosted.com/demo/api/frontend',
    clientKey: 'demo-app:dev.bf8d2a449a025d1715a28f218dd66a40ef4dcc97b661398f7e05ba67',
    refreshInterval: 10,
    appName: 'unleash-demo',
    impressionDataAll: true,
});

unleash.on("impression", (event) => {
    console.log(event)
    window.posthog.capture(event.eventType, {
        ...event.context,
        distinct_id: event.context?.userId, 
        featureName: event.featureName,
        enabled: event.enabled,
        variant: event.variant,
    });
});

unleash.setContextField('userId', userId);
unleash.start();

ReactDOM.render(<App unleash={unleash} userId={userId} />, document.getElementById('root'));
registerServiceWorker();
