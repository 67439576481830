import React, { Component } from 'react';

class Contact extends Component {
  render() {

    return (
      <section id="contact">
         <center>
            <h2>Ready to order?</h2>
            <br />
            Go to <a href="https://www.getunleash.io/">getunleash.io</a> <br />
            and get your instance of Unleash today.
         </center>
      </section>
    );
  }
}

export default Contact;
